// Imports with side-effects:
require('bootstrap');

const LazyLoad = require('vanilla-lazyload').default;

const $ = require('jquery');

// Cookie polyfill
const Cookies = require('js-cookie');

// adds User Agent info to html for browser-specific CSS selection
var b = document.documentElement;
b.setAttribute('data-useragent', navigator.userAgent);
b.setAttribute('data-platform', navigator.platform);
b.className
  += !!('ontouchstart' in window) || !!('onmsgesturechange' in window)
    ? ' touch'
    : '';

$(document).ready(() => {
	// This allows linking to modals by hash
	// const { location: { hash } } = window;
	// if (hash)  {
	// 	$(hash).modal('show');
	// }
	// customize feature modal's content
	$('#feature-modal').on('show.bs.modal', function (event) {
		// Button that triggered the modal
		var button = $(event.relatedTarget);
		var content = button.data('content');
		var image = button.data('image');
		var modal = $(this);
		modal.find('.feature-content').text(content);
		modal.find('.feature-image').attr('src', image);
	});

	const downloadForm = $('.download-demo');

	// Intent here is to intercept the 'click' event on one of the two 'download' buttons and then
	// either point the user to the linux or darwin versions depending on which button they clicked.
	// So the button has a data attribute, download_url. When the user clicks a button, take that
	// data and associate it with the whole downloadForm. Then, we let the browser do the
	// downloadForm validation and we go read this data later on the downloadForm's submit event
	downloadForm.find('button[type=submit]').bind('click', (clickEvent) => {
		const { download_url } = $(clickEvent.target).data();
		downloadForm.data('download_url', download_url);
	});

	downloadForm.submit((submitEvent) => {
		const { download_url } = $(submitEvent.target).data();
		window.open(download_url, 'Download Eddie Trial');
	});

	/**
	 * Hubspot integration
	 * @see https://developers.hubspot.com/docs/methods/forms/submit_form_v3
	 */
	// // Identifier for our portal on hubspot
	// const portalId = $('body').data('hs-portal-id');
	// // Identifier for the 'potential customer' viewing the page:
	// const hubspotutk = Cookies.get('hubspotutk');

	// $('form[data-hs]').each((i, e) => {
	// 	const jqForm = $(e);

	// 	// Form id from Hubspot:
	// 	const formId = jqForm.data('hs-form-id');

	// 	jqForm.submit((submitEvent) => {
	// 	submitEvent.preventDefault();
	// 		const fields = jqForm.serializeArray();
	
	// 		const hubSpotBody = {
	// 			fields,
	// 			skipValidation: true,
	// 			context: {
	// 				hutk: hubspotutk,
	// 				pageUri: window.location.href,
	// 				pageName: document.title,
	// 			},
	// 		};
	
	// 		$.ajax({
	// 			type: "POST",
	// 			url: `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
	// 			data: JSON.stringify(hubSpotBody),
	// 			contentType: "application/json; charset=utf-8",
	// 			dataType: "json",
	// 			success: function(data){
	// 				const { inlineMessage, redirectUri } = data;
	// 				// Injection vulnerability from hubspot:
	// 				if (inlineMessage) {
	// 					jqForm.html(inlineMessage);
	// 				}
	// 				if (redirectUri) {
	// 					jqForm.html(
	// 						`<p>
	// 							You will now be redirected to the sandbox. If the pop up was blocked, click below:
	// 						</p>
	// 						<a class='btn mt-3' href='${redirectUri}'>Go to Sandbox</a>`
	// 					);

	// 					window.open(redirectUri);
	// 				}
	// 			},
	// 			error: function(errMsg) {
	// 				console.error('ERROR:', errMsg);
	// 				jqForm.text('There was some sort of error in submitting your contact info, please contact us by email directly instead at info@datasenseanalytics.com');
	// 			}
	// 		});		
	// 	});
	// })
	// === End Hubspot Integration
});

$(window).scroll(() => {
	const nav = $('nav.navbar');

	// Listen on window scroll and if the user has scrolled at all, darken the navbar and show the
	// 'jump to top' button
	if ($(window).scrollTop() > 0) {
		// User IS at the very top, show nav and scroll to top btn
		nav.addClass('visible');
		$('.scrolltop').fadeIn();
	} else {
		// hide nav + scroll to top btn
		nav.removeClass('visible');
		$('.scrolltop').fadeOut();
	}
});

const lazySelector = '.lazy';

const lazyLoad = new LazyLoad({
	elements_selector: lazySelector,
});

// LazyLoad hooks into a debounced window.scroll event. When we open a modal, there is no such
// event. So here, I hook into bootstrap's emitted event for modal show
$('body').on('show.bs.modal', function (e) {
	// Look inside the modal and force all lazy images to load immediately
	$(e.target).find(lazySelector).each((i, elem) => {
		lazyLoad.load(elem, true);
	});
})
